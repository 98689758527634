@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;1,400&display=swap');

body {
  margin: 0;
}

body, #root {
  min-height: 100vh;
  background-color: rgb(250, 250, 217);
}

* {
  font-family: 'Playfair Display', serif;
}

